export const useFtCookieHandle = () => {
    const getCookieData = (paramData:any) =>{
        if (process.client) {
            const data = document.cookie.split('; ').find(row => row.startsWith(paramData))?.split('=')[1];
            return data;
        }else{
            return null;
        }
    }
    return getCookieData;
}
export default useFtCookieHandle;