<script>

import Cookies from 'js-cookie';
import { debounce } from "lodash-es";
import  cookieHandle  from '~/dy/useFtCookieHandle';
export default {
  name: "FortytwocustomHead",
  props: {
    contextType: {
      type: String,
      required: true,
    },
    contextData: {
      type: Array,
      default: () => ([]),
    },
  },
  head() {
    let addScript = [];
    // let dataRecContext = '';
    // if (this.contextType == 'HOMEPAGE' || this.contextType == 'OTHER' || this.contextType == ''){
    //   dataRecContext = {type:this.contextType};
    // }else{
    //   dataRecContext = {type:this.contextType,data:this.contextData};
    // }
    if (process.server) {
      addScript = [
        {
          hid: 'dy-api-dynamic',
          type: 'text/javascript',
          src: `//cdn.dynamicyield.com/api/${process.env.VSF_DYNAMICYIELD_SECTIONID}/api_dynamic.js`,
        },
        {
          hid: 'dy-api-static',
          type: 'text/javascript',
          src: `//cdn.dynamicyield.com/api/${process.env.VSF_DYNAMICYIELD_SECTIONID}/api_static.js`,
        },
      ];
    }
    // const contextScript = (this.contextData !== '') ? {
    //     hid: 'dy-recommendation-context',
    //     innerHTML: `
    //         //<![CDATA[
    //         window.DY = window.DY || {};
    //         DY.recommendationContext = ${JSON.stringify(dataRecContext)};
    //         //]]>
    //     `,
    //     type: 'text/javascript',
    //     charset: 'utf-8',
    // } : null;

    return {
      script: [
        // ...(contextScript ? [contextScript] : []),
        ...addScript,
      ],
      // __dangerouslyDisableSanitizersByTagID: contextScript ? {
      //   'dy-recommendation-context': ['innerHTML'],
      // } : undefined,
    };
  },
  data() {
    return {
      checkandInterval: null, // Reference to store the repeat
    };
  },
  methods: {
    //Check the _dyid_server is exist else will create new cookie
    checkAndSetCookie() {
      const getCookieData = cookieHandle();
      const checkDYIDSeverExist = getCookieData('_dyid_server') ?? null;
      const dyid_data = getCookieData('_dyid') ?? null;
      if(checkDYIDSeverExist){
        clearInterval(this.checkandInterval);
      }else{
        if (dyid_data) {
          Cookies.set('_dyid_server', dyid_data, { path: '/', expires: 365, secure: true });
          clearInterval(this.checkandInterval);
        }
      }  
    }
  },
  mounted() {
    let dataRecContext = '';

    if (this.contextType === 'HOMEPAGE' || this.contextType === 'OTHER' || this.contextType === '') {
      dataRecContext = { type: this.contextType };
    } else {
      dataRecContext = { type: this.contextType, data: this.contextData };
    }

    window.DY = window.DY || {};
    window.DYO = window.DYO || {};
    window.DY.recommendationContext = dataRecContext;

    window.DY.userActiveConsent = { accepted: true };
    window.DYO?.ActiveConsent?.updateConsentAcceptedStatus(true);

    this.checkandInterval = setInterval(this.checkAndSetCookie, 300); // 300ms interval
  },
  render(h) {
    return h(); // Returning an empty vnode (like a comment)
  },
  beforeDestroy() {
    // Clean up the interval on component destroy
    if (this.checkandInterval) {
      clearInterval(this.checkandInterval);
    }
  },
};
</script>
