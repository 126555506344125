























import LazyHydrate from "vue-lazy-hydration";
import { useRoute, defineComponent, ref, useFetch, computed } from "@nuxtjs/composition-api";
import { useUiState } from "~/composables";
import AppHeader from "~/components/FortyTwo/AppHeader.vue";
import BackToTop from "~/components/FortyTwo/BackToTop.vue";
import BottomNavigation from "~/components/BottomNavigation.vue";
import IconSprite from "~/components/General/IconSprite.vue";
import LoadWhenVisible from "~/components/utils/LoadWhenVisible.vue";
import TopBar from "~/components/TopBar/TopBar.vue";
import useFtGetMobileMenuInfo from '~/composables/FortyTwo/useFtGetMobileMenuInfo';
import { merge } from 'lodash-es';
import { useCategoryStore } from '~/modules/catalog/category/stores/category';
import { useCustomerStore } from '~/modules/customer/stores/customer';

export default defineComponent({
  name: "DefaultLayout",

  components: {
    LoadWhenVisible,
    LazyHydrate,
    AppHeader,
    BackToTop,
    BottomNavigation,
    IconSprite,
    TopBar,
    AppFooter: () =>
      import(/* webpackPrefetch: true */ "~/components/FortyTwo/AppFooter.vue"),
    CartSidebar: () =>
      import(
        /* webpackPrefetch: true */ "~/modules/fortytwo/cart/components/CartSidebar.vue"
      ),
    WishlistSidebar: () =>
      import(
        /* webpackPrefetch: true */ "~/modules/fortytwo/wishlist/components/WishlistSidebar.vue"
      ),
    LoginModal: () =>
      import(
        /* webpackPrefetch: true */ "~/modules/customer/components/LoginModal/LoginModal.vue"
      ),
    Notification: () =>
      import(
        /* webpackPrefetch: true */ "~/components/Notification.vue"
      ),
    MobileCategorySidebar: () =>
      import(
        /* webpackPrefetch: true */ "~/modules/catalog/category/components/sidebar/MobileCategorySidebar/MobileCategorySidebar.vue"
      ),
    ProductGrabPayPriceDividerModal: () =>
      import(
        /* webpackPrefetch: true */ "~/modules/fortytwo/product/components/ProductGrabPayPriceDividerModal.vue"
      ),
  },

  setup() {
    const route = useRoute();
    const {
      isCartSidebarOpen,
      isWishlistSidebarOpen,
      isLoginModalOpen,
      isMobileMenuOpen,
      toggleLoginModal,
    } = useUiState();
    const customerStore = useCustomerStore();

    const { getMobileMenuInfo } = useFtGetMobileMenuInfo();
    const mobileMenuInfo = ref(null);
    const loadMobileMenu = async () => {
        const { data } = await getMobileMenuInfo();
        mobileMenuInfo.value = await merge({}, mobileMenuInfo.value, data.mobileMenuInfor ?? null);
    };

    const loadCategoryMenu = async () => {
      const categories = useCategoryStore();
      if (categories.categories === null) {
        await categories.load();
      }
    };

    const toggleLoginModalClose = async () => {
      await customerStore.setTriggerLoginModal(false);
    };

    useFetch(async () => {
        await loadMobileMenu();
        await loadCategoryMenu();
    });

    return {
      isCartSidebarOpen,
      isWishlistSidebarOpen,
      isLoginModalOpen: computed(() => customerStore.triggerLoginModal),
      isMobileMenuOpen,
      toggleLoginModal,
      route,
      loadMobileMenu,
      mobileMenuInfo,
      toggleLoginModalClose,
    };
  },
  head () {
    const i18nHead = ref(this.$nuxtI18nHead({ addSeoAttributes: true }));

    // to fix url href encoding issue (change 2F% to /)
    i18nHead.value.link.forEach(element => {
      if (element.href) {
          element.href = decodeURIComponent(element.href)
      }
    });

    return {
      htmlAttrs: {
        ...i18nHead.value.htmlAttrs
      },
      meta: [
        ...i18nHead.value.meta
      ],
      link: [
        {
          rel: "stylesheet",
          href: "/_nuxt/fonts.css"
        },
        ...i18nHead.value.link
      ]
    }
  }
});
