var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form"},[_c('div',{staticClass:"form__element form__element--half firstName"},[_c('span',{staticClass:"form__element--label"},[_vm._v(_vm._s(_vm.$t('First Name') + ' *'))]),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"firstName","rules":"required|min:2","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('FtInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('shipping-firstName'),expression:"'shipping-firstName'"}],attrs:{"value":_vm.form.firstname,"name":"firstName","maxlength":"50","valid":!errors[0],"error-message":_vm.$t(errors[0])},on:{"input":function($event){return _vm.changeFormDetails('firstname', $event)}}})]}}])})],1),_vm._v(" "),_c('div',{staticClass:"form__element form__element--half form__element--half-even lastname"},[_c('span',{staticClass:"form__element--label"},[_vm._v(_vm._s(_vm.$t('Last Name') + ' *'))]),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"lastname","rules":"required|min:2","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('FtInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('shipping-lastName'),expression:"'shipping-lastName'"}],attrs:{"value":_vm.form.lastname,"name":"lastName","maxlength":"50","valid":!errors[0],"error-message":_vm.$t(errors[0])},on:{"input":function($event){return _vm.changeFormDetails('lastname', $event)}}})]}}])})],1),_vm._v(" "),_c('div',{staticClass:"form__element form__select sf-select--underlined country"},[_c('span',{staticClass:"form__element--label"},[_vm._v(_vm._s(_vm.$t('Country') + ' *'))]),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"country","rules":"required|min:2","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.countryListDropdown.length > 0)?_c('FtSelect',{key:"country",staticClass:"form__element--country",attrs:{"name":"country","id":"country","required":"","emptyLabel":_vm.$t('Select an option'),"selected":_vm.form.country_code,"dropdownOption":_vm.countryListDropdown,"disabled":_vm.countryReadonly,"valid":!errors[0],"error-message":_vm.$t(errors[0])},on:{"input":_vm.changeCountry},model:{value:(_vm.form.country_code),callback:function ($$v) {_vm.$set(_vm.form, "country_code", $$v)},expression:"form.country_code"}},_vm._l((_vm.countryListDropdown),function(countryOption){return _c('FtSelectOption',{key:countryOption.uid,attrs:{"value":countryOption.uid,"label":countryOption.label,"selected":_vm.form.country_code == countryOption.uid}},[_vm._v("\n          "+_vm._s(countryOption.label)+"\n        ")])}),1):_c('SkeletonLoader',{attrs:{"width":"100%","height":"40px","radius":"20px","margin":"0"}})]}}])})],1),_vm._v(" "),_c('div',{staticClass:"form__element zipCode"},[_c('span',{staticClass:"form__element--label"},[_vm._v(_vm._s(_vm.$t('Postal Code') + ' *'))]),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"zipCode","rules":_vm.postalcodeMaxLength === 6 ? 'required|min:6': 'required',"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('FtInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('shipping-zipcode'),expression:"'shipping-zipcode'"}],staticClass:"form__element--postcode",class:{'multi': _vm.showPostalCodeAddresses},attrs:{"value":_vm.form.postcode,"name":"zipCode","maxlength":_vm.postalcodeMaxLength,"valid":!errors[0],"error-message":_vm.$t(errors[0]),"inputmode":"numeric","pattern":"[0-9]*","autocomplete":"off","data-1p-ignore":""},on:{"input":function($event){_vm.changeFormDetails('postcode', $event);_vm.fetchPostalCode($event)}}}),_vm._v(" "),(_vm.showPostalCodeAddresses)?_c('div',{staticClass:"form__element--postcode-dropdown"},_vm._l((_vm.address_details.details),function(address,index){return _c('span',{key:index,staticClass:"form__element--postcode-dropdown-row",on:{"click":function($event){return _vm.updateAddress(address)}}},[_vm._v("\n          "+_vm._s((address.building_name ? address.building_name + ', ' : '') +
            (address.building_number ? address.building_number + ' ' : '') +
            (address.street_name ? address.street_name : ''))+"\n        ")])}),0):_vm._e()]}}])})],1),_vm._v(" "),(_vm.postalcodeError)?_c('div',{staticClass:"postalcode-error-msg"},[_c('span',[_vm._v(_vm._s(_vm.$t('We have detected that you may have entered an invalid postal code.')))]),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('Please ignore this message if you are sure it\'s accurate.')))])]):_vm._e(),_vm._v(" "),(_vm.showMoreAddress)?[_c('div',{staticClass:"form__element company"},[_c('span',{staticClass:"form__element--label"},[_vm._v(_vm._s(_vm.$t('Company (optional)')))]),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"company","rules":"","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('FtInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('shipping-company'),expression:"'shipping-company'"}],attrs:{"value":_vm.form.company,"name":"company","maxlength":"50","valid":!errors[0],"error-message":_vm.$t(errors[0])},on:{"input":function($event){return _vm.changeFormDetails('company', $event)}}})]}}],null,false,2233681624)})],1),_vm._v(" "),_c('div',{staticClass:"form__element building_name"},[_c('span',{staticClass:"form__element--label"},[_vm._v(_vm._s(_vm.$t('Building Name')))]),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"building_name","rules":"","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('FtInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('shipping-buildingName'),expression:"'shipping-buildingName'"}],attrs:{"value":_vm.form.building_name,"name":"buildingName","maxlength":"50","readonly":_vm.buildingNameReadonly,"valid":!errors[0],"error-message":_vm.$t(errors[0])},on:{"input":function($event){return _vm.changeFormDetails('building_name', $event)}}})]}}],null,false,3400418697)})],1),_vm._v(" "),_c('div',{staticClass:"form__element street"},[_c('span',{staticClass:"form__element--label"},[_vm._v(_vm._s(_vm.$t('Street Address') + ' *'))]),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"street","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('FtInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('shipping-streetName'),expression:"'shipping-streetName'"}],attrs:{"value":_vm.form.street,"name":"streetName","maxlength":"50","readonly":_vm.streetNameReadonly,"valid":!errors[0],"error-message":_vm.$t(errors[0])},on:{"input":function($event){return _vm.changeFormDetails('street', $event)}}})]}}],null,false,2205776521)})],1),_vm._v(" "),_c('div',{staticClass:"form__element form__element--half apartment"},[_c('span',{staticClass:"form__element--label"},[_vm._v(_vm._s(_vm.$t('Block / Lobby')))]),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"apartment","rules":"","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('FtInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('shipping-apartment'),expression:"'shipping-apartment'"}],attrs:{"value":_vm.form.apartment,"name":"apartment","maxlength":"50","valid":!errors[0],"error-message":_vm.$t(errors[0])},on:{"input":function($event){return _vm.changeFormDetails('apartment', $event)}}})]}}],null,false,3854635899)})],1),_vm._v(" "),_c('div',{staticClass:"form__element form__element--half form__element--half-even unit_no"},[_c('span',{staticClass:"form__element--label"},[_vm._v(_vm._s(_vm.$t('Unit / Apt No.') + "" + (_vm.unitNotRequired ? '' : ' *')))]),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"unit_no","rules":("" + (_vm.unitNotRequired ? 'min:1' : 'required|min:1')),"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('FtInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('shipping-unitNo'),expression:"'shipping-unitNo'"}],attrs:{"value":_vm.form.unit_no,"name":"unitNo","maxlength":"10","valid":!errors[0],"error-message":_vm.$t(errors[0])},on:{"input":function($event){return _vm.changeFormDetails('unit_no', $event)}}}),_vm._v(" "),_c('SfCheckbox',{staticClass:"form__checkbox",attrs:{"label":_vm.$t('Not applicable'),"name":"unitNotRequired"},model:{value:(_vm.unitNotRequired),callback:function ($$v) {_vm.unitNotRequired=$$v},expression:"unitNotRequired"}})]}}],null,false,1213159284)})],1)]:_vm._e(),_vm._v(" "),_c('div',{staticClass:"form__element city"},[_c('span',{staticClass:"form__element--label"},[_vm._v(_vm._s(_vm.$t('City') + ' *'))]),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"city","rules":"required|min:2","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('FtInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('shipping-city'),expression:"'shipping-city'"}],attrs:{"value":_vm.form.city,"name":"city","maxlength":"50","readonly":_vm.cityReadonly,"valid":!errors[0],"error-message":_vm.$t(errors[0])},on:{"input":function($event){return _vm.changeFormDetails('city', $event)}}})]}}])})],1),_vm._v(" "),_c('div',{staticClass:"form__element form__element--half phone"},[_c('span',{staticClass:"form__element--label"},[_vm._v(_vm._s(_vm.$t('Telephone No.') + ' *'))]),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"phone","rules":_vm.telMaxLength === 8 ? 'required|min:8': 'required',"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('FtInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('shipping-phone'),expression:"'shipping-phone'"}],attrs:{"name":"phone","maxlength":_vm.telMaxLength,"valid":!errors[0],"error-message":_vm.$t(errors[0]),"inputmode":"numeric","pattern":"[0-9]*"},on:{"input":function($event){return _vm.changeFormDetails('telephone', $event)}},model:{value:(_vm.form.telephone),callback:function ($$v) {_vm.$set(_vm.form, "telephone", $$v)},expression:"form.telephone"}})]}}])})],1),_vm._v(" "),_c('div',{staticClass:"form__element form__element--half form__element--half-even alt_telephone"},[_c('span',{staticClass:"form__element--label"},[_vm._v(_vm._s(_vm.$t('Alternate Telephone No.')))]),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"alt_telephone","rules":"","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('FtInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('shipping-altTelephone'),expression:"'shipping-altTelephone'"}],attrs:{"name":"altTelephone","maxlength":_vm.altTelMaxLength,"valid":!errors[0],"error-message":_vm.$t(errors[0]),"inputmode":"numeric","pattern":"[0-9]*"},on:{"input":function($event){return _vm.changeFormDetails('alt_telephone', $event)}},model:{value:(_vm.form.alt_telephone),callback:function ($$v) {_vm.$set(_vm.form, "alt_telephone", $$v)},expression:"form.alt_telephone"}})]}}])})],1),_vm._v(" "),_c('div',{staticClass:"form__element form__element--half form__element--half-even",attrs:{"hidden":""}},[_c('span',{staticClass:"form__element--label"},[_vm._v("Building Type")]),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"building_type","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('FtInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('shipping-buildingType'),expression:"'shipping-buildingType'"}],attrs:{"name":"buildingType","maxlength":"50","valid":!errors[0],"error-message":_vm.$t(errors[0]),"readonly":""},on:{"input":function($event){return _vm.changeFormDetails('building_type', $event)}},model:{value:(_vm.form.building_type),callback:function ($$v) {_vm.$set(_vm.form, "building_type", $$v)},expression:"form.building_type"}})]}}])})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }