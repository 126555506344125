import {
  computed, readonly, ref, useContext, useRoute,
} from '@nuxtjs/composition-api';
import { addItemCommand } from '~/modules/checkout/composables/useCart/commands/addItemCommand';
import { applyCouponCommand } from '~/modules/checkout/composables/useCart/commands/applyCouponCommand';
import { loadCartCommand } from '~/modules/checkout/composables/useCart/commands/loadCartCommand';
import { loadTotalQtyCommand } from '~/modules/checkout/composables/useCart/commands/loadTotalQtyCommand';
import { removeCouponCommand } from '~/modules/checkout/composables/useCart/commands/removeCouponCommand';
import { removeItemCommand } from '~/modules/checkout/composables/useCart/commands/removeItemCommand';
import { updateItemQtyCommand } from '~/modules/checkout/composables/useCart/commands/updateItemQtyCommand';
import { Logger } from '~/helpers/logger';
import { Cart, CartItemInterface, ProductInterface, ProductStockStatus } from '~/modules/GraphQL/types';
import { useCartStore } from '~/modules/checkout/stores/cart';
import { useWishlist } from '~/modules/wishlist/composables/useWishlist';
import { Product } from '~/modules/catalog/product/types';
import { ComposableFunctionArgs } from '~/composables';
import { UseCartErrors, UseCartInterface } from './useCart';
import { useApi } from "~/composables/useApi";
import { getItem } from "~/helpers/asyncLocalStorage";
import useFtSendFacebookPixel from '~/composables/FortyTwo/useFtSendFacebookPixel';
import useFtDynamicYieldEvent from '~/dy/useFtDynamicYieldEvent';
import { merge } from "lodash-es";

/**
 * Allows loading and manipulating cart of the current user.
 *
 * See the {@link UseCartInterface} for a list of methods and values available in this composable.
 */
export function useCart<CART extends Cart, CART_ITEM extends CartItemInterface, PRODUCT extends ProductInterface>(): UseCartInterface<
CART,
CART_ITEM,
PRODUCT
> {
  const loading = ref<boolean>(false);
  const error = ref<UseCartErrors>({
    addItem: null,
    removeItem: null,
    updateItemQty: null,
    load: null,
    clear: null,
    applyCoupon: null,
    removeCoupon: null,
    loadTotalQty: null,
  });
  const { app, $cookies } = useContext();
  const { query } = useApi();
  const context = app.$vsf;
  const route = useRoute();
  const cartStore = useCartStore();
  const cart = computed(() => cartStore.cart as CART);
  const apiState = context.$magento.config.state;
  const { loading: wishlistLoading, afterAddingWishlistItemToCart } = useWishlist();
  const { sendFacebookPixel } = useFtSendFacebookPixel();
  const sendDYEvent  = useFtDynamicYieldEvent();

  /**
   * Assign new cart object
   * @param newCart
   *
   * @return void
   */
  const setCart = (newCart: CART | Cart): void => {
    Logger.debug('useCart.setCart', newCart);

    cartStore.$patch((state) => {
      state.cart = newCart;
    });
  };

    /**
   * merging new cart object
   * @param newCart
   *
   * @return void
   */
    const mergeCart = (newCart: CART | Cart): void => {
      Logger.debug('useCart.mergeCart', newCart);
  
      cartStore.$patch((state) => {
        // merge instead of replace state cart, to avoid removing delivery group data in state 
        let tempCart = { ...state.cart, ...newCart };
        if (newCart.items){
          tempCart.items = state.cart.items.map((item, index) => {
            const matchingItem = newCart.items.find(item2 => item2.uid === item.uid);
            return {...item, ...matchingItem}
          })
        }
        // only come in when both state cart and new cart not empty
        if (newCart.shipping_addresses && newCart.shipping_addresses.length 
          && state.cart.shipping_addresses && state.cart.shipping_addresses.length 
        ){
          tempCart.shipping_addresses = state.cart.shipping_addresses.map((item, index) => ({
            ...item, 
            ...newCart.shipping_addresses[index] // Merge the corresponding shipping address from newCart
          }))
        }
        state.cart = tempCart;
      });
    };

  /**
   * Check if product is in the cart
   * @param product
   *
   * @return boolean
   */
  const isInCart = (product: PRODUCT): boolean => !!cart.value?.items?.find((cartItem) => cartItem?.product?.uid === product.uid);

  const load = async ({ customQuery = {}, customHeaders = {}, realCart = false } = { customQuery: { cart: 'cart' }, customHeaders: {} }): Promise<void> => {
    Logger.debug('useCart.load');

    try {
      loading.value = true;
      const loadedCart = await loadCartCommand.execute(context, { customQuery, customHeaders, realCart });
      if (loadedCart) {
        cartStore.$patch((state) => {
          state.cart = loadedCart;
          $cookies.set('vsf-cart-stringify', JSON.stringify(state.cart), {path:'/', maxAge: 900});
        });
      }
      error.value.load = null;
    } catch (err) {
      error.value.load = err;
      Logger.error('useCart/load', err);
    } finally {
      loading.value = false;
    }
  };

  const clear = async ({ customQuery, customHeaders } = { customQuery: { cart: 'cart' }, customHeaders: {} }): Promise<void> => {
    Logger.debug('useCart.clear');

    try {
      loading.value = true;
      context.$magento.config.state.removeCartId();
      const loadedCart = await loadCartCommand.execute(context, { customQuery, customHeaders });

      cartStore.$patch((state) => {
        state.cart = loadedCart;
      });
    } catch (err) {
      error.value.clear = err;
      Logger.error('useCart/clear', err);
    } finally {
      loading.value = false;
    }
  };

  const loadTotalQty = async (params?: ComposableFunctionArgs<{}>): Promise<void> => {
    Logger.debug('useCart.loadTotalQty');

    try {
      loading.value = true;
      const totalQuantity = await loadTotalQtyCommand.execute(context, params);

      cartStore.$patch((state) => {
        state.cart.total_quantity = totalQuantity;
      });
    } catch (err) {
      error.value.loadTotalQty = err;
      Logger.error('useCart/loadTotalQty', err);
    } finally {
      loading.value = false;
    }
  };

  const addItem = async ({
    product, quantity, productConfiguration, customQuery = { addProductsToCart: 'addProductsToCart' }, customHeaders,
  }): Promise<void> => {
    Logger.debug('useCart.addItem', { product, quantity });

    try {
      loading.value = true;

      if (!apiState.getCartId()) {
        await load({ realCart: true });
      }

      const updatedCart = await addItemCommand.execute(context, {
        currentCart: cart.value,
        product,
        quantity,
        productConfiguration,
        customQuery,
        customHeaders,
      });

      //send AddtoCart Event to DY via API
      const msbcItemNo = product.__typename == 'SimpleProduct' ? (product?.msbc_item_no ?? '') : product.sku;
      const productPrice = product.__typename == 'ConfigurableProduct' ? (product?.configurable_product_options_selection.variant.price_range.maximum_price.final_price.value ?? '') : product.price_range.maximum_price.final_price.value;
      const dataDYEventContent = {
        'event_type': "Add to Cart",
        'product_sku': msbcItemNo,
        'price': productPrice,
        'quantity': quantity
      }; 
      if(msbcItemNo){
        await sendDYEvent(dataDYEventContent,'');
        // facabook pixel and conversions api
        await sendFacebookPixel('AddToCart', msbcItemNo,
          {
            'id': msbcItemNo,
            'quantity': quantity,
            'item_price': product.price_range.maximum_price.final_price.value?? 0
          }
        );
      }


      error.value.addItem = null;
      cartStore.$patch((state) => {
        state.cart = updatedCart;
      });

    } catch (err) {
      error.value.addItem = err;
      Logger.error('useCart/addItem', err);
    } finally {
      if (!wishlistLoading.value && route.value.query?.wishlist) {
        afterAddingWishlistItemToCart({
          product,
          cartError: error.value.addItem,
        });
      }
      loading.value = false;
    }
  };

  const removeItem = async ({ product, customQuery = { removeItemFromCart: 'removeItemFromCart' }, customHeaders }) => {
    Logger.debug('useCart.removeItem', { product });
    try {
      loading.value = true;
      const { data, errors } = await removeItemCommand.execute(context, {
        currentCart: cart.value,
        product,
        customQuery,
        customHeaders,
      });
      
      if (errors){
        error.value.removeItem = {name: "", message: errors[0].message};
        Logger.error('useCart/removeItem', error.value.removeItem);
      }
      else{
        error.value.removeItem = null;
      }
      
      if (data.removeItemFromCart){
        cartStore.$patch((state) => {
          state.cart = data.removeItemFromCart.cart as unknown as Cart;
        });
        //send RemoveCart Event to DY via API
        const msbcItemNo = product.__typename == 'SimpleProduct' ? (product?.msbc_item_no ?? '') : product?.product.sku;
        const dataDYEventContent = {
          'event_type': "Remove from Cart",
          'product_sku': msbcItemNo,
          'price': product?.prices.price.value,
          'quantity': product?.quantity
        }; 
        if(msbcItemNo){
          await sendDYEvent(dataDYEventContent,'');
        }
      }
      
    } catch (err) {
      error.value.removeItem = err;
      Logger.error('useCart/removeItem', err);
      await load({ realCart: true });
    } finally {
      loading.value = false;
    }
  };

  const updateItemQty = async ({ product, quantity, customQuery = { updateCartItems: 'updateCartItems' } }): Promise<void> => {
    Logger.debug('useCart.updateItemQty', {
      product,
      quantity,
    });

    if (quantity && quantity > 0) {
      try {
        loading.value = true;
        const updatedCart = await updateItemQtyCommand.execute(context, {
          currentCart: cart.value,
          product,
          quantity,
          customQuery,
        });

        //send AddtoCart Event to DY via API
        const msbcItemNo = product.product.__typename == 'SimpleProduct' ? (product.product?.msbc_item_no ?? '') : product.product.sku;
        const productPrice = product.__typename == 'ConfigurableProduct' ? (product?.product.configurable_product_options_selection.variant.price_range.maximum_price.final_price.value ?? '') : product.product.price_range.maximum_price.final_price.value;
        const dataDYEventContent = {
          'event_type': "Add to Cart",
          'product_sku': msbcItemNo,
          'price': productPrice,
          'quantity': quantity
        }; 
        if(msbcItemNo){
          await sendDYEvent(dataDYEventContent,'');
        }

        error.value.updateItemQty = null;
        cartStore.$patch((state) => {
          state.cart = updatedCart;
        });
      } catch (err) {
        error.value.updateItemQty = err;
        Logger.error('useCart/updateItemQty', err);
      } finally {
        loading.value = false;
      }
    }
  };

  const handleCoupon = async (couponCode = null, customQuery = null): Promise<void> => {
    const variables = {
      currentCart: cart.value,
      customQuery,
      couponCode,
    };

    const { updatedCart, errors } = couponCode
      ? await applyCouponCommand.execute(context, variables)
      : await removeCouponCommand.execute(context, variables);

    if (errors) {
      throw errors[0];
    }

    if (updatedCart) {
      // merge instead of replace state cart, to avoid removing delivery group data in state 
      mergeCart(updatedCart)
      // cartStore.$patch((state) => {
      //   state.cart = updatedCart;
      // });
    }
  };

  const applyCoupon = async ({ couponCode, customQuery }): Promise<void> => {
    Logger.debug('useCart.applyCoupon');

    try {
      loading.value = true;
      await handleCoupon(couponCode, customQuery);
      error.value.applyCoupon = null;
    } catch (err) {
      error.value.applyCoupon = err;
      Logger.error('useCart/applyCoupon', err);
    } finally {
      loading.value = false;
    }
  };

  const removeCoupon = async ({ customQuery }): Promise<void> => {
    Logger.debug('useCart.removeCoupon');

    try {
      loading.value = true;
      await handleCoupon(null, customQuery);
      error.value.applyCoupon = null;
    } catch (err) {
      error.value.removeCoupon = err;
      Logger.error('useCart/removeCoupon', err);
    } finally {
      loading.value = false;
    }
  };

  const canAddToCart = (product: Product, qty = 1) => {
    // eslint-disable-next-line no-underscore-dangle
    if (product?.__typename === 'ConfigurableProduct') {
      return !!product?.configurable_product_options_selection?.variant
        ?.uid;
    }
    const inStock = product?.stock_status === ProductStockStatus.InStock ? true : false;
    const stockLeft = product?.only_x_left_in_stock === null
      ? true
      : qty <= product?.only_x_left_in_stock;
    return inStock && stockLeft;
  };

  const getDiscountLabel = () : string => {
    const discounts = ref(cart.value.prices.discounts);
    const dicountLabel = ref('');
    const specificLabel = ref(false);
    if (discounts.value.length){
      if (discounts.value.filter(e => e.label !== 'Discount').length > 0) {
        /* vendors contains the element we're looking for */
        specificLabel.value = true;
      }
      if (specificLabel.value){
        for (let i = 0; i < discounts.value.length; i++) {
          if (i != 0 ){
            dicountLabel.value += ", ";
          }
          if (dicountLabel.value != 'Discount'){
            dicountLabel.value += discounts.value[i].label;
          }
        
        }
      }
      
    }
    return dicountLabel.value;
  }

  return {
    setCart,
    mergeCart,
    cart,
    loadTotalQty,
    isInCart,
    addItem,
    load,
    removeItem,
    clear,
    updateItemQty,
    applyCoupon,
    removeCoupon,
    canAddToCart,
    loading: readonly(loading),
    error: readonly(error),
    getDiscountLabel
  };
}

export default useCart;
export * from './useCart';
