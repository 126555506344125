























































import {
  SfSidebar, SfList, SfMenuItem
} from '@storefront-ui/vue';
import FtMegaMenuSidebar from "~/components/customSFUI/vue/src/components/organisms/FtMegaMenuSidebar/FtMegaMenuSidebar.vue";
import FtMegaMenuItem from "~/components/customSFUI/vue/src/components/molecules/FtMegaMenuItem/FtMegaMenuItem.vue";
import {
  defineComponent, useRouter, useContext, ref, useFetch
} from '@nuxtjs/composition-api';
import { useUiState } from '~/composables';
import linkClickRouting from '~/static/custom/js/linkClickRouting.js';

export default defineComponent({
  components: {
    SfSidebar,
    SfList,
    SfMenuItem,
    FtMegaMenuSidebar,
    FtMegaMenuItem,
  },
  mixins: [linkClickRouting],
  props: {
    mobileMenuInfo: {
      type: Object,
      default: () => [],
    },
  },
  setup(props) {
    const { isMobileMenuOpen, toggleMobileMenu } = useUiState();
    const router = useRouter();
    const { localePath,$config } = useContext();
    const showMainMenu = ref(true);
    const megaMenuMain = ref([]);
    const megaMenuLvl1 = ref([]);
    const megaMenuLvl2 = ref([]);
    const currentCategoryUID = ref(null);
    const currentCategoryName = ref(null);
    const currentCategoryLink = ref(null);
    const currentCategoryLevel = ref(null);
    let prefixurl = $config.storeUrl;
    const navigateMenu = (category) => {
      let path = category.url_path;
      let prefixurlLength = prefixurl.length;
      if(prefixurl.charAt(prefixurlLength - 1) == '/'){
        prefixurl = prefixurl.slice(0, -1)
      }
      if(category.custom_class == "menu-single-link"){
        navigateLevel0(path);
      }else{
        toggleMobileMenu();
        router.push(path);
        //window.location.href = `${prefixurl}${path}`;
      }
    };
    const navigateLevel0 = (path) => {
      toggleMobileMenu();
      if(path.substring(0,1) == '/'){
        path = path.replace('/',"");
      }
      router.push(localePath('/' + path));
      //window.location.href = localePath('/' + path);
    };

    const closeAndResetMenu = () => {
      showMainMenu.value = true;
      currentCategoryUID.value = null;
      currentCategoryName.value = null;
      //currentCategoryLink.value = null;
      currentCategoryLevel.value = null;
      history.value = [];
      toggleMobileMenu();
     
    };

    const history = ref([]);
    const onGoCategoryDown = (menuUid, categoryName, categoryLink, categoryLevel) => {
      history.value.push({uid:menuUid,name:categoryName,link:categoryLink,level:categoryLevel});
      currentCategoryUID.value = menuUid;
      currentCategoryName.value = categoryName;
      currentCategoryLink.value = categoryLevel == 0 ? '/'+categoryLink.replace('/',"") : categoryLink;
      currentCategoryLevel.value = categoryLevel;
      showMainMenu.value = false;
    };

    const onGoCategoryUp = () => {
      history.value.pop();
      let currentCatagory = history.value.slice(-1);
      currentCategoryUID.value = currentCatagory[0]?.uid ?? null;
      currentCategoryName.value = currentCatagory[0]?.name ?? null;
      currentCategoryLink.value = currentCatagory[0]?.link ?? null;
      currentCategoryLevel.value = currentCatagory[0]?.level ?? null;
      if(!currentCategoryName.value){
        showMainMenu.value = true;
      }
    };

    useFetch(async () => {
      for(const level0 of props.mobileMenuInfo.item){
          megaMenuMain.value.push({
            uid: level0.uid,
            name: level0.name,
            url_path: level0.custom_class == 'menu-single-link' ? '/'+level0.url_path : level0.url_path,
            parentUid: null,
            level: level0.level,
            position: level0.position,
            custom_class: level0.custom_class ?? "",
            children: level0.children
          });
        for(const level1 of level0.children){
            megaMenuLvl1.value.push({
              uid: level1.uid,
              name: level1.name,
              url_path: level1.url_path,
              parentUid: level0.uid,
              level: level1.level,
              position: level1.position,
              custom_class: level1.custom_class ?? "",
              children: level1.children
            });
          for(const level2 of level1.children){
              let myClass = level1.name.toLowerCase().replace(' & ','-').replace(' / ','-').replace(/ \+ /g,'-').replace(/\s/g,'-').replace('\'s','') + ' ' 
              + 'menu-' + level2.name.toLowerCase().replace(' & ','-').replace(' / ','-').replace(/ \+ /g,'-').replace(/\s/g,'-').replace('\'s','') +
              (level2.custom_class ? ' ' + level2.custom_class : '');
              
              megaMenuLvl2.value.push({
                uid: level2.uid,
                name: level2.name,
                url_path: level2.url_path,
                parentUid: level0.uid+level1.uid,
                level: level2.level,
                position: level2.position,
                custom_class: myClass,
                children: level2.children
              });
          }
        }
      }
    });

    return {
      onGoCategoryUp,
      onGoCategoryDown,
      history,
      navigateMenu,
      navigateLevel0,
      isMobileMenuOpen,
      toggleMobileMenu,
      showMainMenu,
      currentCategoryUID,
      currentCategoryName,
      currentCategoryLink,
      currentCategoryLevel,
      megaMenuMain,
      megaMenuLvl1,
      megaMenuLvl2,
      closeAndResetMenu
    };
  },
});
