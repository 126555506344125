<template>
  <div class="ftmegamenu-bar">
    <div :class="{ 'display-none': title == 'Menu' }">
      <slot name="back">
        <SfButton
          :class="{ 'display-none': !back }"
          :aria-label="'back'"
          class="ftmegamenu-button--pure ftmegamenu-bar__back-icon back-icon"
          type="button"
          @click="$emit('click:back')"
        >
        <nuxt-img class="ftmegamenu__go-back" src="/custom/storefront_menu/mobile/go_back.svg" alt="back_icon"/>
        </SfButton>
      </slot>
    </div>
    <div :class="{ 'ftmegamenu-bar__header': title == 'Menu' }">
      <slot name="title" v-bind="{ title }">
        <nuxt-img v-if="title == 'Menu'" src="/custom/footer/logo.png" alt="FortyTwo" class="ftmegamenu-bar__fortytwologo"/>
        <div v-else class="ftmegamenu-bar__title">{{ title }}</div>
      </slot>
    </div>
    <div class="ftmegamenu-bar__close-container">
      <slot name="close">
        <SfButton
          :class="{ 'display-none': !close }"
          class="ftmegamenu-button--pure ftmegamenu-bar__close-icon close-icon"
          :aria-label="'close'"
          type="button"
          @click="$emit('click:close')"
        >
        <nuxt-img class="ftmegamenu__go-back" src="/custom/storefront_menu/mobile/close.svg" alt="close_icon"/>
        </SfButton>
      </slot>
    </div>
  </div>
</template>
<script>
import SfIcon from "@storefront-ui/vue/src/components/atoms/SfIcon/SfIcon.vue";
import SfButton from "@storefront-ui/vue/src/components/atoms/SfButton/SfButton.vue";
export default {
  name: "FtMegaMenuBar",
  components: {
    SfIcon,
    SfButton,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    back: {
      type: Boolean,
      default: false,
    },
    close: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss">
@import "~/components/customSFUI/shared/styles/components/molecules/FtMegaMenuBar/FtMegaMenuBar.scss";
</style>
