import { useApi } from '~/composables/useApi';

export const useFtGetShippingAllowCountries = () => {
  const { query } = useApi();

  const getShippingAllowCountries:any = async () =>{
    const myGraphqlQuery = `
      query {
        getShippingAllowCountries{
          countries {
            id
            full_name_english
            full_name_locale
            two_letter_abbreviation
          }
        }
      }
      `;  
    const { data } = await query(myGraphqlQuery);

    return {
      data,
    };
  }
  
  return {
    getShippingAllowCountries,
  }
}

export default useFtGetShippingAllowCountries;