import { useApi } from '~/composables/useApi';

export const oldOrderReviewInfo = () => {
  const { query } = useApi();

  const getOrderInfo:any = async (oid) =>{
    const myGraphqlQuery = `
      query getOldOrderInfo($oid: String!){
        getOldOrderInfo(oid: $oid){
          custName
          orderDate
          orderNo
          orderItems {
            productSku
            productImage
            productName
            productOptions {
              optionLabel
              optionValue
            }
            productPrice
            productQty
          }
        }
      }
      `;  
    const { data: { getOldOrderInfo }, errors } = await query(myGraphqlQuery, {oid: oid});

    return {
      getOldOrderInfo,
      errors
    };
  }

  const createOldOrderReviews = async (form, oid) => {
    const myGraphqlQuery = `
      mutation createOldOrderReviews($inputs: [CreateProductReviewInput!], $oid: String!){
        createOldOrderReviews(inputs: $inputs, oid: $oid){
          error
        }
      }
      `;  
      const { data, errors } = await query(myGraphqlQuery, { inputs: form, oid: oid });

    return {
      data,
      errors
    };
  }
  
  return {
    getOrderInfo,
    createOldOrderReviews
  }
}


export default oldOrderReviewInfo;