import { computed, reactive } from "@nuxtjs/composition-api";
import { StateInterface, UseUiStateInterface } from "~/composables/useUiState/useUiState";

const state = reactive<StateInterface>({
  isCartSidebarOpen: false,
  isWishlistSidebarOpen: false,
  isLoginModalOpen: false,
  isNewsletterModalOpen: false,
  isCategoryGridView: true,
  isWishlistGridView: true,
  isFilterSidebarOpen: false,
  isFilterColumnOpen: false,
  isMobileMenuOpen: false,
  isAddressModalOpen: false,
  isAddressListModalOpen: false,
  isProductImageModalOpen: false,
  isForgetPasswordForm: false,
  isGrabPayPriceDividerModalOpen: false,
});

/**
 * Global store for managing UI state.
 *
 * See the {@link UseUiStateInterface} for a list of methods and values available in this composable.
 */
export function useUiState(): UseUiStateInterface {
  const toggleMobileMenu = () => {
    state.isMobileMenuOpen = !state.isMobileMenuOpen;
  };

  const toggleCartSidebar = () => {
    if (state.isMobileMenuOpen) toggleMobileMenu();
    state.isCartSidebarOpen = !state.isCartSidebarOpen;
  };

  const toggleWishlistSidebar = () => {
    if (state.isMobileMenuOpen) toggleMobileMenu();
    state.isWishlistSidebarOpen = !state.isWishlistSidebarOpen;
  };

  const toggleLoginModal = () => {
    if (state.isMobileMenuOpen) toggleMobileMenu();
    state.isLoginModalOpen = !state.isLoginModalOpen;
  };

  const toggleNewsletterModal = () => {
    state.isNewsletterModalOpen = !state.isNewsletterModalOpen;
  };

  const changeToCategoryGridView = () => {
    state.isCategoryGridView = true;
  };
  const changeToCategoryListView = () => {
    state.isCategoryGridView = false;
  };

  const changeToWishlistGridView = () => {
    state.isWishlistGridView = true;
  };

  const changeToWishlistListView = () => {
    state.isWishlistGridView = false;
  };
  const toggleFilterSidebar = () => {
    state.isFilterSidebarOpen = !state.isFilterSidebarOpen;
  };
  const toggleFilterColumn = () => {
    state.isFilterColumnOpen = !state.isFilterColumnOpen;
  };

  const toggleAddressModal = () => {
    state.isAddressModalOpen = !state.isAddressModalOpen;
  };

  const toggleAddressListModal = () => {
    state.isAddressListModalOpen = !state.isAddressListModalOpen;
  };

  const toggleProductImageModal = () => {
    state.isProductImageModalOpen = !state.isProductImageModalOpen;
  };

  const toggleForgetPasswordForm = () => {
    state.isForgetPasswordForm = !state.isForgetPasswordForm;
  };

  const toggleGrabPayPriceDividerModal = () => {
    state.isGrabPayPriceDividerModalOpen = !state.isGrabPayPriceDividerModalOpen;
  };

  return {
    isMobileMenuOpen: computed(() => state.isMobileMenuOpen),
    isCartSidebarOpen: computed(() => state.isCartSidebarOpen),
    isWishlistSidebarOpen: computed(() => state.isWishlistSidebarOpen),
    isLoginModalOpen: computed(() => state.isLoginModalOpen),
    isNewsletterModalOpen: computed(() => state.isNewsletterModalOpen),
    isCategoryGridView: computed(() => state.isCategoryGridView),
    isWishlistGridView: computed(() => state.isWishlistGridView),
    isFilterSidebarOpen: computed(() => state.isFilterSidebarOpen),
    isFilterColumnOpen: computed(() => state.isFilterColumnOpen),
    isAddressModalOpen: computed(() => state.isAddressModalOpen),
    isAddressListModalOpen: computed(() => state.isAddressListModalOpen),
    isProductImageModalOpen: computed(() => state.isProductImageModalOpen),
    isForgetPasswordForm: computed(() => state.isForgetPasswordForm),
    isGrabPayPriceDividerModalOpen: computed(() => state.isGrabPayPriceDividerModalOpen),
    toggleMobileMenu,
    toggleCartSidebar,
    toggleWishlistSidebar,
    toggleLoginModal,
    toggleNewsletterModal,
    changeToCategoryGridView,
    changeToCategoryListView,
    changeToWishlistGridView,
    changeToWishlistListView,
    toggleFilterSidebar,
    toggleFilterColumn,
    toggleAddressModal,
    toggleAddressListModal,
    toggleProductImageModal,
    toggleForgetPasswordForm,
    toggleGrabPayPriceDividerModal,
  };
}

export default useUiState;
export * from "./useUiState";
