<template>
  <div class="ftmegamenu-sidebar" :class="{ 'ftmegamenu-sidebar--right': position === 'right' }">
    <SfOverlay :visible="visibleOverlay" />
    <transition :name="transitionName" appear>
      <aside v-show="visible && isOpen" ref="asideContent" v-focus-trap v-click-outside="checkPersistence"
        class="ftmegamenu-sidebar__aside">
        <slot name="bar">
          <FtMegaMenuBar :title="title" class="smartphone-tablet-only" :back="true" :close="true"
            @click:back="title == 'Menu' ? close() : $emit('onGoCategoryUp')" @click:close="close()" />
        </slot>
        <slot name="circle-icon" v-bind="{ close, button }">
          <SfCircleIcon :class="{ 'display-none': !button }" icon-size="12px" :aria-label="'Close sidebar'" icon="cross"
            class="ftmegamenu-sidebar__circle-icon desktop-only" @click="close" />
        </slot>
        <div :class="{ 'display-none': !title || (!title && !hasTop) }" class="ftmegamenu-sidebar__top">
          <slot name="title" v-bind="{ title, subtitle, headingLevel }">
            <SfHeading :class="{ 'display-none': !title }" :title="title" :description="subtitle" :level="headingLevel"
              class="
                ftmegamenu-heading--left ftmegamenu-heading--no-underline
                ftmegamenu-sidebar__title
                desktop-only
              " />
          </slot>
          <slot name="content-top" />
        </div>
        <div class="ftmegamenu-sidebar__content">
          <slot />
        </div>
        <div :class="{ 'display-none': !hasBottom }" class="ftmegamenu-sidebar__bottom">
          <slot name="content-bottom" />
        </div>
      </aside>
    </transition>
  </div>
</template>
<script>

import { focusTrap } from "@storefront-ui/vue/src/utilities/directives/";
import { clickOutside } from "@storefront-ui/vue/src/utilities/directives/";
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import { isClient } from "@storefront-ui/vue/src/utilities/helpers";
import FtMegaMenuBar from "~/components/customSFUI/vue/src/components/molecules/FtMegaMenuBar/FtMegaMenuBar.vue";
import SfCircleIcon from "@storefront-ui/vue/src/components/atoms/SfCircleIcon/SfCircleIcon.vue";
import SfOverlay from "@storefront-ui/vue/src/components/atoms/SfOverlay/SfOverlay.vue";
import SfHeading from "@storefront-ui/vue/src/components/atoms/SfHeading/SfHeading.vue";

export default {
  name: "FtMegaMenuSidebar",
  directives: { focusTrap, clickOutside },
  components: {
    SfCircleIcon,
    SfOverlay,
    SfHeading,
    FtMegaMenuBar,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
    headingLevel: {
      type: Number,
      default: 3,
    },
    button: {
      type: Boolean,
      default: true,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    overlay: {
      type: Boolean,
      default: true,
    },
    persistent: {
      type: Boolean,
      default: false,
    },
    position: {
      type: String,
      default: "left",
      validator: (value) => ["left", "right"].includes(value),
    },
  },
  data() {
    return {
      transition: this.position,
      isOpen: this.visible,
    };
  },
  computed: {
    visibleOverlay() {
      return this.visible && this.overlay;
    },
    transitionName() {
      return "sf-slide-" + this.transition;
    },
    hasTop() {
      return this.$slots.hasOwnProperty("content-top");
    },
    hasBottom() {
      return this.$slots.hasOwnProperty("content-bottom");
    },
  },
  watch: {
    visible: {
      handler(value) {
        if (!isClient) return;
        if (value) {
          this.isOpen = true;
          this.transition = this.position;
          this.$nextTick(() => {
            const sidebarContent = document.getElementsByClassName(
              "ftmegamenu-sidebar__content"
            )[0];
            disableBodyScroll(sidebarContent);
          });
          document.addEventListener("keydown", this.keydownHandler);
        } else {
          clearAllBodyScrollLocks();
          document.removeEventListener("keydown", this.keydownHandler);
          this.isOpen = false;
        }
      },
      immediate: true,
    },
    isOpen: {
      // handle out animation for async load component
      handler(value) {
        if (!isClient) return;
        if (!value) {
          this.transition = this.position === "right" ? "left" : "right";
        }
      },
    },
  },
  beforeDestroy() {
    clearAllBodyScrollLocks();
  },
  methods: {
    close() {
      this.isOpen = false;
      this.$emit("close");
    },
    checkPersistence() {
      if (!this.persistent) this.close();
    },
    keydownHandler(e) {
      if (e.key === "Escape" || e.key === "Esc" || e.keyCode === 27) {
        this.close();
      }
    },
  },
};
</script>
<style lang="scss">
@import "~/components/customSFUI/shared/styles/components/organisms/FtMegaMenuSidebar/FtMegaMenuSidebar.scss";
</style>
