import { useApi } from '~/composables/useApi';

export const useSalesOrderList = () => {
  const { query } = useApi();

  const getMsbcSalesOrderList:any = async (currentPage, pageSize) =>{
    const myGraphqlQuery = `
    query getMsbcSalesOrderList($currentPage: Int, $pageSize: Int){
      getMsbcSalesOrderList(currentPage: $currentPage, pageSize: $pageSize) {
        total_count
        page_info {
          page_size
          current_page
          total_pages
        }
        items {
          msbcNo
          orderNo
          orderDate
          amountInclGST
          transactionType
          status
          shipToName
        }
      }
    }
      `;  

    const { data } = await query(myGraphqlQuery, {currentPage: currentPage, pageSize: pageSize});
    return {
      data,
    };
  }
  
  return {
    getMsbcSalesOrderList,
  }
}


export default useSalesOrderList;