
























































































































import {
  ref,
  defineComponent,
  computed,
  PropType,
  watch,
  onMounted,
  onBeforeUnmount,
} from '@nuxtjs/composition-api';
import {
  SfModal,
  SfHeading,
  SfButton,
  SfIcon,
  SfCheckbox,
} from '@storefront-ui/vue';
import FtModal from "~/components/customSFUI/vue/src/components/molecules/FtModal/FtModal.vue";
import FtRadio from "~/components/customSFUI/vue/src/components/molecules/FtRadio/FtRadio.vue";
import AddressForm from '~/modules/fortytwo/checkout/components/AddressForm.vue';
import ProductAlertMsg from '~/modules/fortytwo/product/components/ProductAlertMsg.vue';
import { ValidationObserver } from 'vee-validate';
import { Countries } from '~/composables';
import { useUiState } from '~/composables/useUiState';
import userAddressesGetters from '~/modules/customer/getters/userAddressesGetters';
import { CheckoutAddressForm, getInitialCheckoutAddressForm } from '~/helpers/checkout/address';
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";

export default defineComponent({
  name: 'FortyTwoAddressListModal',
  components: {
    SfModal,
    SfHeading,
    SfButton,
    SfIcon,
    SfCheckbox,
    FtModal,
    FtRadio,
    AddressForm,
    ProductAlertMsg,
    ValidationObserver,
  },
  props: {
    addresses: {
      type: Array as any,
      default: [],
    }, 
    currentAddressId: {
      type: Number,
      default: null,
    },
    countries: {
      type: Array as PropType<Countries[]>,
      default: () => [],
    },
    sameAsShipping: {
      type: Boolean,
      default: true,
    },
    addressFormAction: {
      type: String,
      default: 'edit',
    },
    addressFormType: {
      type: String,
      default: '',
    },
    shippingAllowCountries: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['updateSameAsShipping','updateAddressFormAction','handleAddressFormSubmit','close'],
  setup(props, { emit }) {
    const { isAddressListModalOpen } = useUiState();
    const selectedAddress = ref(props.currentAddressId);
    const form = ref<CheckoutAddressForm>(getInitialCheckoutAddressForm());
    const showPostalCodeAddresses = ref(false);
    const alertMsg = ref('');
    const addressFormRef = ref(null);

    const formTitle = computed(() => {
      let title;
      if (props.addressFormAction == 'edit') {
        title = 'My Addresses';
      } else if (props.addressFormAction == 'create') {
        title = 'Enter an Alternate Address';
      } else {
        title = props.addressFormType == 'shipping' ? 'Enter Delivery Address' : 'Enter New Address';
      }
      return title;
    });

    const addressesArray = computed(() => props.addresses
    .filter((address) => props.addressFormType == 'shipping' ? props.shippingAllowCountries.includes(address.country_code) : address.country_code != '')
    .map((address) => ({
      id: address.id,
      firstName: userAddressesGetters.getFirstName(address),
      lastName: userAddressesGetters.getLastName(address),
      street: userAddressesGetters.getStreetName(address),
      streetNumber: userAddressesGetters.getApartmentNumber(address),
      postalCode: userAddressesGetters.getPostCode(address),
      city: userAddressesGetters.getCity(address),
      province: userAddressesGetters.getProvince(address) || '',
      country: userAddressesGetters.getCountry(address),
      countryName: props.countries
        .find(({ id }) => id === address.country_code)
        ?.full_name_locale
        ?? address.country_code,
      company: userAddressesGetters.getCompany(address),
      phone: userAddressesGetters.getPhone(address),
      isDefault: userAddressesGetters.isDefault(address),
      isDefaultShipping: userAddressesGetters.isDefaultShipping(address) ? 'Default Delivery Address' : '',
      isDefaultBilling: userAddressesGetters.isDefaultBilling(address) ? 'Default Billing Address' : '',
      building_name: userAddressesGetters.getBuildingName(address),
      unit_no: userAddressesGetters.getUnitNo(address),
      alt_telephone: userAddressesGetters.getAltTelephone(address),
      building_type: userAddressesGetters.getBuildingType(address)
    })));

    const submitForm = () => {
      addressFormRef.value.validate().then(success => {
        if (!success) {
          const errors = addressFormRef.value.errors;
          const errorFields = [];
          for (const [key, value] of Object.entries(errors)) {
            if (value[0]) {
              errorFields.push(key);
            }
          }
          const [first] = errorFields;
          document.querySelector(`.form__element.${first}`).scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
          return;
        }

        if (props.addressFormAction == 'edit') {
          emit('handleAddressFormSubmit', props.addresses.filter((address) => address.id === selectedAddress.value)[0]); 
        } else {
          if(showPostalCodeAddresses.value) {
            alertMsg.value = 'Please select postal code address.';
            return;
          }
          const result = {
            firstname: form.value.firstname,
            lastname: form.value.lastname,
            company: form.value.company,
            city: form.value.city,
            country_code: form.value.country_code,
            street: [form.value.street, form.value.apartment],
            region: form.value.region,
            postcode: form.value.postcode,
            telephone: form.value.telephone,
            building_name: form.value.building_name,
            unit_no: form.value.unit_no,
            alt_telephone: form.value.alt_telephone,
            building_type: form.value.building_type
          }
          emit('handleAddressFormSubmit', result); 
        }
        emit('close');
      });
    };

    const updateFormDetail = (field: keyof CheckoutAddressForm, value: string) => {
      form.value[field] = value;
    };

    onMounted(() => {
      if (process.client) {
        const modalContent = document.getElementsByClassName("address-modal__content")[0];
        disableBodyScroll(modalContent);
      }
    });

    onBeforeUnmount(()=>{
      clearAllBodyScrollLocks();
    });

    watch(
      () => showPostalCodeAddresses.value,
      (newShowPostalCodeAddresses) => {
        if (newShowPostalCodeAddresses != null){
          if (!newShowPostalCodeAddresses && alertMsg.value) {
            alertMsg.value = '';
          }
        }
      },
    );

    return {
      isAddressListModalOpen,
      selectedAddress,
      formTitle,
      addressesArray,
      form,
      showPostalCodeAddresses,
      submitForm,
      alertMsg,
      updateFormDetail,
      addressFormRef,
    };
  },
});
