




















import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'FortyTwoProductAlertMsg',
  props: {
    msg: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: 'danger',
    },
  },
});
