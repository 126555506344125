export default {
    data() {
        let links;
      return {
        links
      };
    },
  
    mounted() {

      if (this.$router) {
        this.addListeners();
      }
    },
  
    beforeUnmount() {
      if (this.$router) {
        this.removeListeners();
      }
    },
  
    updated() {
      if (this.$router) {
        this.removeListeners();
        this.$nextTick(() => {
          this.addListeners();
        });
      }
    },
  
    methods: {
      navigate(event) {
        const href = event.target.getAttribute("href");
        const target = event.target.getAttribute("target");
        if (href && href[0] === "/" && target !== "_blank") {
          event.preventDefault();
          this.$router && this.$router.push(href);
        }
      },
  
      addListeners() {
        this.links = this.$el.getElementsByTagName("a");
        for (let i = 0; i < this.links.length; i++) {
          this.links[i].addEventListener("click", this.navigate, false);
        }
      },
  
      removeListeners() {
        for (let i = 0; i < this.links.length; i++) {
          this.links[i].removeEventListener("click", this.navigate, false);
        }
        this.links = [];
      }
    }
  };