import { useApi } from '~/composables/useApi';
import axios from "axios";
import { useContext, ref } from '@nuxtjs/composition-api';
import { merge } from "lodash-es";
import cookieHandle from '~/dy/useFtCookieHandle';

export const useFtDynamicYieldEvent = () => {
    const { query } = useApi();
    const { $cookies } = useContext();
    const getCookieData = cookieHandle();
    const dataDYjsession = ref();
    const sendDYEvent = async (paramData:any, paramData1:any) =>{
        try{
            //const eventType = paramData.event_type;
            // if(eventType == 'Remove from Cart' || eventType == 'Add to Cart'){
            //     console.log('checkdata',paramData1);
            //     const dataCartItem = ref({});
            //     const dataCart = ref([]);
            //     paramData1.items.forEach((item) =>{
            //         dataCartItem.value = merge({}, dataCartItem.value, 
            //         { 
            //         'typename': item.product.__typename,
            //         'msbc_item_no': item.product.msbc_item_no,
            //         'sku': item.product.sku,
            //         'quantity': item.quantity,
            //         'price_total_including_tax': item.prices.row_total_including_tax,
            //         'total_item_discount': item.prices.total_item_discount 
            //         });
            //         dataCart.value.push(dataCartItem.value);
            //     })
            //     paramData.cart = dataCart.value;
            // }

            const checkDYIDServer = getCookieData('_dyid_server') ?? '';
            const checkDYID = getCookieData('_dyid') ?? '';
            const checkDyjsession = getCookieData('_dyjsession') ?? '';
            if(checkDyjsession){
                 dataDYjsession.value = checkDyjsession;
            }else{
                if (process.client) {
                    dataDYjsession.value = window.DY.jsession;
                }
            }
            const clientdata = await axios.get('/client-header');
            const clientIpAddress = clientdata.data.clientipaddress;
            const encodedClientIpAddress = window.btoa(clientIpAddress) ?? '';
            const dyid_servers = window.btoa(checkDYIDServer) ?? '';
            const dyid_data = window.btoa(checkDYID) ?? '';
            const dyid_sessions = window.btoa(dataDYjsession.value) ?? '';
            const dyEventInfo = Buffer.from(JSON.stringify(paramData)).toString('base64');
            const myGraphqlQuery = `
            query dyeventinit
                (
                $dyid_data: String!,
                $dyid_server: String!,
                $dyid_session: String!,
                $dyid_ipdata: String!,
                $data: String!
                ){
                    dyeventinit(
                    dyid_ipdata: $dyid_ipdata, 
                    dyid_data: $dyid_data, 
                    dyid_server: $dyid_server,
                    dyid_session: $dyid_session, 
                    data: $data
                    ) 
                        {
                            event_type
                        }
                }
            `;
            const variable = {
                dyid_data: dyid_data,
                dyid_ipdata: encodedClientIpAddress,
                dyid_server: dyid_servers,
                dyid_session: dyid_sessions,
                data: dyEventInfo
            };
            if(checkDYIDServer && checkDYID){ query(myGraphqlQuery, variable); }   
        }catch(error){
            console.error(error);
        }
    }
    return sendDYEvent;
}

export default useFtDynamicYieldEvent;