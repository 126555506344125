import { useApi } from '~/composables/useApi';
import { computed, onMounted, ComputedRef, ref, readonly, DeepReadonly } from "@nuxtjs/composition-api";
// import moment from 'moment';
import { merge } from 'lodash-es';

export interface AddressDetails {
  building_number: String
  building_name: String
  street_name: String
  building_desc: String
  building_code: String
  error: Boolean
  error_message: String
}

export const pcQuery = () => {
  const { query } = useApi();

  const address_details = ref({
    error: false,
    error_message: '',
    details: {
      building_code: '',
      building_desc: '',
      building_name: '',
      building_number: '',
      street_name: ''
    }
  });

  const queryAddressDetails = async (pc) => {
    const graphqlQuery = `
      query get_address_details($pc: Int!){
        get_address_details (pc: $pc) {
            error
            error_message
            details {
              building_number
              building_name
              street_name
              building_desc
              building_code
            }
          }
        }
    `;

    const { data: { get_address_details } } = await query<{ get_address_details: AddressDetails }>(graphqlQuery, {pc: pc});

    address_details.value = merge({}, address_details.value, get_address_details);
  }

  return {
    queryAddressDetails,
    address_details
  }
}

export default pcQuery;