












import { defineComponent } from '@nuxtjs/composition-api';
import { SfIcon } from '@storefront-ui/vue';

export default defineComponent({
    name: 'FortytwoFooterSection7',
    components: {
        SfIcon,
    },
})
