






import { defineComponent } from '@nuxtjs/composition-api';
import linkClickRouting from '~/static/custom/js/linkClickRouting.js';

export default defineComponent({
  name: 'HTMLContent',
  mixins: [linkClickRouting], // temporary remove nuxt link on category page until we find a fix on nuxt link slowness
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    content: {
      type: String,
      default: '',
    },
  },
});
