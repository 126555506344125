<template>
  <component :is="componentIs" class="ftmegamenu-menu-item" v-bind="bind" :data-testid="label" v-on="$listeners">
    <slot name="icon" />
    <slot name="label" v-bind="{ label }">
      <span :class="'ftmegamenu-menu-item__label-level' + level + ' label-' + label">{{ label }}</span>
      <nuxt-img v-if="countryBlock" class="ftmegamenu-menu-item__expand-more-arrow-down"
        src="/custom/storefront_menu/mobile/expand_more_arrow_down.svg" alt="expand_icon" />
    </slot>
    <slot name="count" v-bind="{ count }">
      <span class="ftmegamenu-menu-item__count">{{ count }}</span>
    </slot>
    <slot name="mobile-nav-icon" v-bind="{ icon }">
      <nuxt-img v-if="hasChildren" class="ftmegamenu-menu-item__expand-more"
        src="/custom/storefront_menu/mobile/expand_more.svg" alt="expand_icon" />
    </slot>
  </component>
</template>
<script>
import SfIcon from "@storefront-ui/vue/src/components/atoms/SfIcon/SfIcon.vue";
import SfLink from "@storefront-ui/vue/src/components/atoms/SfLink/SfLink.vue";
import SfButton from "@storefront-ui/vue/src/components/atoms/SfButton/SfButton.vue";

export default {
  name: "FtMegaMenuItem",
  components: {
    SfIcon,
    SfLink,
    SfButton,
  },
  props: {
    label: {
      type: String,
      default: "",
    },
    icon: {
      type: [String, Array],
      default: "chevron_right",
    },
    count: {
      type: [String, Number],
      default: "",
    },
    link: {
      type: [String, Object],
      default: null,
    },
    level: {
      type: Number,
      default: 0,
    },
    hasChildren: {
      type: Boolean,
    },
    countryBlock: {
      type: Boolean,
    }
  },
  computed: {
    bind() {
      const bind = {};
      if (this.link) {
        bind.link = this.link;
      } else {
        bind.class = "ftmegamenu-button--pure";
      }
      return bind;
    },
    componentIs() {
      return this.link ? "SfLink" : "SfButton";
    },
  },
};
</script>
<style lang="scss">
@import "~/components/customSFUI/shared/styles/components/molecules/FtMegaMenuItem/FtMegaMenuItem.scss";
</style>
