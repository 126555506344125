






import { 
  defineComponent,
  useContext,
  useRouter,
  onMounted,
  ref,
  getCurrentInstance,
} from "@nuxtjs/composition-api";
import { merge } from "lodash-es";
import { useApi } from '~/composables/useApi';
import PaymentLoader from '~/modules/fortytwo/payment/components/PaymentLoader.vue';
import { usePaymentStore } from '~/modules/fortytwo/payment/stores/payment';
export default defineComponent({
  name: "Response",
  // using the empty layout.
  layout: 'empty',
  components: {
    PaymentLoader,
  },
  async asyncData(context) {
    return {
      bodyData: context["req"]["body"],
    }
  },
  setup(){

    const { app, $config, app:{i18n} } = useContext();
    const storeUrl = $config.storeUrl;
    const paymentLoadingMessage = i18n.t("Redirecting you back to {storeURL}. Please wait...", {storeURL: storeUrl});
    const paymentStore = usePaymentStore();

    onMounted(async () => {
      const { query } = useApi();
      const router = useRouter();
      const cybsComplete:any = ref({});
      const form = getCurrentInstance().data.bodyData;
      console.log('post data');
      
      const myGraphqlQuery = `
      mutation {
          cybsResponse(input: {
            form_data: "${ Buffer.from(JSON.stringify(form).replace(/"/g, '\\"'), 'utf-8').toString('base64') }"
          }) {
            order_id
            error
            message
          }
        }
      `; 
      
      const fetchCybsCompleteExtendedData = async () => {
        const { data } = await query(myGraphqlQuery);
        cybsComplete.value = await merge({}, cybsComplete.value, data);

        if (!cybsComplete?.value?.cybsResponse?.error) {
          //success. redirect to success page with order number
          const paymentRoute = app.localeRoute({
            name: "thank-you",
            query: {
              order: window.btoa(cybsComplete?.value?.cybsResponse?.order_id),
            },
          });
          
          await router.push(paymentRoute);
        } else {
          await paymentStore.setMessage(cybsComplete?.value?.cybsResponse?.message);
          //error. redirect to cart?
          const paymentRoute = app.localeRoute({
            name: "cart",
          });
          await router.push(paymentRoute);
        }
      };
       await Promise.all([fetchCybsCompleteExtendedData()]);
    });

    return {
      paymentLoadingMessage,
    }

  }
});
