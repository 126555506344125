






























import {
    ref,
    defineComponent,
    useFetch,
    onMounted,
    useAsync,
} from '@nuxtjs/composition-api';
import { merge } from 'lodash-es';
import oldOrderReviewInfo from '~/modules/review/helper/oldOrderReviewInfo';
import ProductReview from '~/components/FortyTwo/review/ProductReview.vue';
import { getMetaInfo } from '~/helpers/getMetaInfo';

export default defineComponent({
    name: 'ReviewOrderPage',
    props: ["oid"],
    components: {
        ProductReview,
    },
    head() {  
      const page = {
        title: 'Review',
      };
      return getMetaInfo(page);
    },
    setup(props) {
      const { getOrderInfo, createOldOrderReviews } = oldOrderReviewInfo();
      const orderinfo = ref(null);
      const oldOrder = ref(true);
      const reviewSent = ref(false);
      const loadingSave = ref(false);

      const orderId = props.oid;
      const fetchOrderInfoError = ref([]);
      const saveOldOrderReviewError = ref([]);

      const fetchOrderInfoData = async () => {
        const { getOldOrderInfo, errors } = await getOrderInfo(props.oid);
        orderinfo.value = merge({}, orderinfo.value, getOldOrderInfo ?? null);
        if (errors){
          fetchOrderInfoError.value = errors;
        }
      };
      const saveOrderReview = async (form) => {
        loadingSave.value = true;
        try {
          const { data, errors } = await createOldOrderReviews(form.value, props.oid);
          if (errors) {
              saveOldOrderReviewError.value = errors;
          }

          reviewSent.value = true;
          loadingSave.value = false;

        } catch {
          
        }
        
      };

      // useFetch(async () => {
      //     await fetchOrderInfoData();
      // });

      onMounted(async () => {
        await fetchOrderInfoData();
      });

      return {
          orderinfo,
          orderId,
          oldOrder,
          fetchOrderInfoError,
          saveOldOrderReviewError,
          saveOrderReview,
          reviewSent,
          loadingSave
      };

    }
});
