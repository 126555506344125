

































































































































































































































































































































import {
  ref,
  defineComponent,
  computed,
  onMounted,
  watch,
  PropType,
} from '@nuxtjs/composition-api';
import {
  SfSelect, SfCheckbox,
} from '@storefront-ui/vue';
import FtInput from "~/components/customSFUI/vue/src/components/atoms/FtInput/FtInput.vue";
import FtSelect from "~/components/customSFUI/vue/src/components/molecules/FtSelect/FtSelect.vue"; 
import { required, min, max, digits } from 'vee-validate/dist/rules';
import { ValidationProvider, extend } from 'vee-validate';
import { useCountrySearch } from "~/composables";
import type { Country } from '~/modules/GraphQL/types';
import pcQuery from '~/modules/fortytwo/sglocate/pcQuery';
import addressGetter from '~/modules/customer/getters/addressGetter';
import { CheckoutAddressForm, getInitialCheckoutAddressForm } from '~/helpers/checkout/address';
import useFtGetShippingAllowCountries from '~/composables/FortyTwo/useFtGetShippingAllowCountries';
import { merge } from "lodash-es";
import SkeletonLoader from "~/components/SkeletonLoader/index.vue";
import { useUser } from '~/modules/customer/composables/useUser';
import userGetters from '~/modules/customer/getters/userGetters';

extend('required', {
  ...required,
  message: 'This field is required',
});
extend('min', {
  ...min,
  message: 'This field should have {length} characters',
});
extend('max', {
  ...max,
  message: 'This field should have {length} characters',
});
extend('digits', {
  ...digits,
  message: 'Please provide a valid phone number',
});

export default defineComponent({
  name: 'FortyTwoCheckoutAddressForm',
  components: {
    SfSelect,
    SfCheckbox,
    FtInput,
    FtSelect,
    ValidationProvider,
    SkeletonLoader,
  },
  props: {
    form: {
      type: Object as PropType<CheckoutAddressForm>,
      default: getInitialCheckoutAddressForm(),
    },
    showPostalCodeAddresses: {
      type: Boolean,
      default: false,
    },
    addressFormType: {
      type: String,
      default: '',
    },
  },
  emits: ['updateShowPostalCodeAddress','updateFormDetail'],
  setup(props, { emit }) {
    const {
      load: loadCountries,
      search: searchCountry,
    } = useCountrySearch();
    const countries = ref<Country[]>([]);
    const country = ref<Country | null>(null);
    const { queryAddressDetails, address_details } = pcQuery();
    const { getShippingAllowCountries } = useFtGetShippingAllowCountries();
    const postalcodeMaxLength = ref(50);
    const telMaxLength = ref(50);
    const altTelMaxLength = ref(50);
    const buildingNameReadonly = ref(false);
    const streetNameReadonly = ref(false);
    const countryReadonly = ref(false);
    const cityReadonly = ref(false);
    const unitNotRequired = ref(false);
    const postalcodeError = ref(false);
    const showMoreAddress = ref(false);
    const countryListDropdown = ref([]);
    const countriesList = computed(() => addressGetter.countriesList(countries.value));
    const { user } = useUser();

    const changeFormDetails = (field: keyof CheckoutAddressForm, value: string) => {
      emit('updateFormDetail', field, value);
    };

    const changeCountry = async (id: string) => {
      changeFormDetails('country_code', id);
      const newCountry = await searchCountry({ id });
      emit('updateFormDetail', 'region', '');
      country.value = newCountry;
      
      emit('updateFormDetail', 'postcode', '');
      emit('updateFormDetail', 'telephone', '');
      emit('updateFormDetail', 'alt_telephone', '');
      emit('updateShowPostalCodeAddress', false);
      fillUpAddress('','','','');
      postalcodeError.value = false;
      if (country.value?.two_letter_abbreviation === 'SG') {
        postalcodeMaxLength.value = 6;
        telMaxLength.value = 8;
        altTelMaxLength.value = 8;
        showMoreAddress.value = false;
      } else {
        postalcodeMaxLength.value = 50;
        telMaxLength.value = 50;
        altTelMaxLength.value = 50;
        showMoreAddress.value = true;
      }
      
    };

    const updCountryAndCity = (countriesList) => {
      // if only one option of country dropdown, prepopulated the country option and set country to readonly
      if (countriesList.length == 1) {
        emit('updateFormDetail', 'country_code', countriesList[0].abbreviation);
        countryReadonly.value = true;
        // if country is SG, then city prepopulated with country label and set city to readonly
        if (props.form.country_code == 'SG') {
          emit('updateFormDetail', 'city', countriesList[0].label);
          cityReadonly.value = true;
          postalcodeMaxLength.value = 6;
          telMaxLength.value = 8;
          altTelMaxLength.value = 8;
        }
      }
    };

    // return result object with value of uid, label
    const fetchCountries = (country) => {
      let result = {};
      result =  merge({}, result, { uid : country.abbreviation });
      result =  merge({}, result, { label : country.label });
      return result;
    };

    // assign value for countryListDropdown array
    const fetchCountryListDropdown = (countries) => {
      countryListDropdown.value = [];
      if (countries && countries.length > 0) {
        countries.forEach((country)=>{
          countryListDropdown.value.push(fetchCountries(country));
        });
      }
    };

    // prefill firstname and lastname
    const prefillName = () => {
      emit('updateFormDetail', 'firstname', userGetters.getFirstName(user.value));
      emit('updateFormDetail', 'lastname', userGetters.getLastName(user.value));
    };

    onMounted(async () => {
      prefillName();
      const [loadedCountries] = await Promise.all([loadCountries()]);

      if (props.addressFormType == 'shipping'){
        const { data } = await getShippingAllowCountries();
        countries.value = data?.getShippingAllowCountries?.countries ?? loadedCountries;
        updCountryAndCity(countriesList.value);
      } else {
        countries.value = loadedCountries;
      }

      fetchCountryListDropdown(countriesList.value);
    });

    watch(
      () => countriesList,
      (newcountriesList) => {
        if (newcountriesList.value != null){
          if (props.addressFormType == 'shipping'){
            updCountryAndCity(newcountriesList.value);
          }
        }
      },
      { deep:true, immediate: true },
    );

    const fillUpAddress = (building_number, street_name, building_name, building_type) => {
      //check if there is building number. if so, prepend to street with spacing
      buildingNameReadonly.value = building_name ? true : false;
      streetNameReadonly.value = street_name ? true : false;
      const building_no = building_number ? building_number + ' ' : '' ;
      emit('updateFormDetail', 'street', building_no + street_name.toString());
      emit('updateFormDetail', 'building_name', building_name);
      emit('updateFormDetail', 'building_type', building_type);
      showMoreAddress.value = true;
    }

    const updateAddress = async (selectedAddress) => {
      await fillUpAddress(
        selectedAddress.building_number,
        selectedAddress.street_name,
        selectedAddress.building_name,
        selectedAddress.building_desc
      );
      emit('updateShowPostalCodeAddress', false);
    };

    const fetchPostalCode = async (pc) => {
      if (props.form.country_code == 'SG') {
        if (pc.length == 6) {
          // reset
          buildingNameReadonly.value = false;
          streetNameReadonly.value = false;
          address_details.value = {
            error: false,
            error_message: '',
            details: {
              building_code: '',
              building_desc: '',
              building_name: '',
              building_number: '',
              street_name: ''
            }
          };
          //execute GraphQl to get address details with postcode
          await queryAddressDetails(pc);
          if (!address_details.value.error_message && Array.isArray(address_details.value.details)) {
            postalcodeError.value = false;
            //check if there are multiple addresses for this postalcode
            if (Object.keys(address_details.value.details).length > 1) {
              emit('updateShowPostalCodeAddress', true);
            } else {
              await fillUpAddress(
                address_details.value.details[0].building_number,
                address_details.value.details[0].street_name,
                address_details.value.details[0].building_name ?? '',
                address_details.value.details[0].building_desc ?? ''
              );
            }
          } else {
            postalcodeError.value = true;
            // reset the field to empty
            await fillUpAddress('','','','');
          }
        } else {
          // reset the field to empty
          await fillUpAddress('','','','');
          showMoreAddress.value = false;
          postalcodeError.value = false;
          emit('updateShowPostalCodeAddress', false);
        }
      }
    }

    return {
      changeFormDetails,
      changeCountry,
      countriesList,
      countries,
      fetchPostalCode,
      postalcodeMaxLength,
      telMaxLength,
      altTelMaxLength,
      buildingNameReadonly,
      streetNameReadonly,
      countryReadonly,
      cityReadonly,
      unitNotRequired,
      postalcodeError,
      showMoreAddress,
      address_details,
      updateAddress,
      countryListDropdown,
    };
  },
});
