





























































































import {
  SfTabs,
  SfTable,
  SfButton,
  SfLink,
  SfPagination,
  SfSelect,
  SfLoader,
} from '@storefront-ui/vue';
import {
  ref, computed, defineComponent, useRoute, onMounted
} from '@nuxtjs/composition-api';
import LazyHydrate from 'vue-lazy-hydration';
import orderGetters from '~/modules/checkout/getters/orderGetters';
import { useUiHelpers } from '~/composables';
import type { CustomerOrder } from '~/modules/GraphQL/types';
import { OrderStatusEnum } from '~/modules/customer/enums/OrderStatusEnum';
import { useSalesOrderList } from '~/modules/customer/composables/useSalesOrderList';
import { merge } from 'lodash-es';
import { getMetaInfo } from '~/helpers/getMetaInfo';

export default defineComponent({
  name: 'OrderHistory',
  components: {
    LazyHydrate,
    SfButton,
    SfLink,
    SfPagination,
    SfSelect,
    SfTable,
    SfTabs,
    SfLoader,
  },
  setup() {
    const { getMsbcSalesOrderList } = useSalesOrderList();
    const route = useRoute();
    const th = useUiHelpers();
    const {
      query: {
        page,
        itemsPerPage,
      },
    } = route.value;

    const rawOrders = ref(null);

    const isOrderReady = ref(false);

    const based64encode = (value) => { return window.btoa(value);};

    onMounted(async () => {
      const { data } = await getMsbcSalesOrderList(
        Number.parseInt(page as string, 10) || 1, 
        Number.parseInt(itemsPerPage as string, 10) || 10);
        rawOrders.value = merge({}, rawOrders.value, data.getMsbcSalesOrderList ?? null);
        isOrderReady.value = true;
    });

    const tableHeaders = [
      'Order #',
      'Date',
      'Ship To',
      'Total',
      'Status',
      'View',
    ];

    const getStatusTextClass = (order: CustomerOrder) => {
      switch (order.status) {
        case OrderStatusEnum.OPEN:
          return 'text-warning';
        case OrderStatusEnum.COMPLETE:
          return 'text-success';
        default:
          return '';
      }
    };

    const pagination = computed(() => orderGetters.getPaginationCustom(rawOrders.value));

    return {
      getStatusTextClass,
      orderGetters,
      getDate: orderGetters.getDate,
      getGrandTotalCustom: orderGetters.getGrandTotalCustom,
      getOrderCurrency: orderGetters.getOrderCurrency,
      orders: computed(() => rawOrders.value?.items ?? []),
      pagination,
      tableHeaders,
      th,
      isOrderReady,
      based64encode,
    };
  },
  head() {  
    const page = {
      title: 'My Orders',
    };
    return getMetaInfo(page);
  },
});
