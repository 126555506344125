import { UseContextReturn } from '~/types/core';
import type { Cart } from '~/modules/GraphQL/types';
import type { PaymentMethodParams } from '../usePaymentProvider';

export const setPaymentMethodOnCartCommand = {
  execute: async (context: UseContextReturn, params: PaymentMethodParams): Promise<Cart | null> => {
    const { data } = await context.app.$vsf.$magento.api.setPaymentMethodOnCart(params, { setPaymentMethodOnCart: "setPaymentMethodOnCart" }, params?.customHeaders);

    return (data?.setPaymentMethodOnCart?.cart as Cart) ?? null;
  },
};
